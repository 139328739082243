import React from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";

const Container = styled.div`
  display: none;
  z-index: 10001;
  transition: transform 0.5s ease;

  ${down("lg")} {
    backface-visibility: hidden;
    display: block;
    height: 2.25em;
    left: 0;
    position: fixed;
    top: 0;
    width: 3.25em;
    transform: translateX(${(props) => props.theme.sideBarWidth});
  }
`;

const Toggle = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  z-index: 21;

  &:before {
    font-family: FontAwesome;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    content: "\f0c9";
    color: #fff;
    font-size: 18px;
    line-height: 2.25em;
    background: rgba(128, 136, 144, 0.5);
    border-radius: 0.35em;
    text-align: center;
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    display: block;
    width: 3.25em;
    height: 2.25em;
    line-height: 2.25em;
  }
`;

export default function TopNav({ onMenuClick = () => {}, sideBarVisible }) {
  return (
    <Container sideBarVisible={sideBarVisible}>
      <Toggle
        sideBarVisible={sideBarVisible}
        href="/#"
        onClick={(e) => {
          e.preventDefault();
          onMenuClick();
        }}
      >
        {" "}
      </Toggle>
    </Container>
  );
}
