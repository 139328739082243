import React from "react";
import Scrollspy from "react-scrollspy";
import Scroll from "../Scroll";
import styled from "styled-components";

const Navigation = styled.nav`
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding-left: 0;

      &.active {
        a {
          background: rgba(0, 0, 0, 0.15);
          box-shadow: inset 0 0 0.25em 0 rgba(0, 0, 0, 0.125);
          color: #fff;

          span:before {
            color: #e27689;
          }
        }
      }

      a {
        display: block;
        padding: 0.5em 1.5em 0.5em 1.5em;
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
        outline: 0;
        border: 0;
        transition: none;

        span {
          position: relative;
          display: block;
          font-size: 0.8em;

          &:before {
            position: absolute;
            left: 0;
            color: #41484c;
            text-align: center;
            width: 1.25em;
            line-height: 1.75em;
          }
        }
      }
    }
  }
`;

const Icon = styled.span`
  text-decoration: none;

  &:before {
    display: inline-block;
    font-family: FontAwesome;
    font-size: 1.25em;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  > .label {
    display: none;
  }
`;

export default function Nav({ sections = [] }) {
  return (
    <Navigation>
      <ul>
        <Scrollspy items={sections.map((s) => s.id)} currentClassName="active" offset={-300}>
          {sections.map((s) => {
            return (
              <li key={s.id}>
                <Scroll type="id" element={s.id}>
                  <a href={`#${s.id}`}>
                    <Icon className={`icon ${s.icon}`}>{s.name}</Icon>
                  </a>
                </Scroll>
              </li>
            );
          })}
        </Scrollspy>
      </ul>
    </Navigation>
  );
}
