import React from "react";
import Nav from "./Nav";
import TopNav from "./TopNav";
import config from "../../../config";
import styled from "styled-components";
import { down } from "styled-breakpoints";

const Container = styled.div`
  display: block;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: ${(props) => props.theme.sideBarWidth};
  background: #303030;
  z-index: 20;
  color: #fff;
  text-align: right;
`;

const Header = styled.div`
  transition: transform 0.5s ease;
  ${down("lg")} {
    ${(props) =>
      props.sideBarVisible ? `transform: translateX(0);` : `transform: translateX(-${props.theme.sideBarWidth});`}
    position: fixed;
    left: 0;
    top: 0;
    width: ${(props) => props.theme.sideBarWidth};
    bottom: 0;
    z-index: 22;
  }
`;

export default function SideBar({ sections = [], sideBarVisible, onSideBarVisibleChange }) {
  return (
    <Header sideBarVisible={sideBarVisible}>
      <TopNav
        title={config.authorName}
        sideBarVisible={sideBarVisible}
        onMenuClick={() => onSideBarVisibleChange(!sideBarVisible)}
      />
      <Container>
        <Nav sections={sections} />
      </Container>
    </Header>
  );
}
